<template>
  <div class="pl-3">
    <v-container class="container--fluid rounded-lg bg-white px-0 app-height-global mt-3">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-progress-linear
            color="#0C2FF3"
            background-color="#F2F4F7"
            value="30"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="back-btn-container px-8"
        >
          <div class="back-btn">
            <router-link
              :to="{
                name: 'CasesFolder',
                params: {
                  handle: $route.params.handle,
                  key: $route.params.key,
                  folderUid: selectedFolderId
                }
              }"
            >
              <v-icon>mdi-chevron-left</v-icon> {{ $t('backToTestCases') }}
            </router-link>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="block main-area"
        >
          <h2 class="mb-3">
            {{ $t('importFromCSV') }}
          </h2>
          <div class="mb-1 text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('chooseFile') }}
            </v-label>
          </div>
          <fileInput
            v-model="csvFile"
            class="w-full mb-2"
            @onFileChange="handleChange"
          />
          <div class="mb-1 text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('importTo') }}
            </v-label>
          </div>
          <v-select
            dense
            height="38px"
            :items="importData"
            item-value="value"
            item-text="title"
            placeholder="Test placeholder"
            append-icon="mdi-chevron-down"
            class="rounded-lg field-theme custom-prepend"
            background-color="#F9F9FB"
          />
          <div class="mb-1 text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('template') }}
            </v-label>
          </div>
          <v-select
            dense
            height="38px"
            :items="templateData"
            append-icon="mdi-chevron-down"
            class="rounded-lg field-theme custom-prepend"
            background-color="#F9F9FB"
            item-value="value"
            item-text="title"
            placeholder="Test placeholder"
          />
          <div class="mb-1 text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('name') }}
            </v-label>
          </div>
          <v-text-field
            dense
            height="38px"
            class="rounded-lg field-theme"
            background-color="#F9F9FB"
            placeholder="Name"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="flex justify-end"
        >
          <v-btn
            class="text-capitalize btn-theme px-6 py-2 mr-6 mb-4"
            color="primary"
            width="207px"
            depressed
            height="40"
            @click="goToMapping"
          >
            {{ $t('goToColumnMapping') }}
          </v-btn>
        <!-- to="/tests/cases/import/csv/columns" -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import fileInput from '@/components/base/FileInput.vue';
export default {
  components: {
    fileInput
  },
  data(){
    return {
      selectedFolderId:   this.$store.state.folder.selectedFolderUID || 1,
      importData: [
        { title: "Android", value: 1 },
        { title: "Web", value: 2 },
        { title: "App", value: 3 },
      ],
      templateData: [
        { title: "Text", value: 1 },
        { title: "App", value: 2 },
      ],
      csvFile: null,
      contents: [],
    }
  },
  computed: {
    contentsHasData() {
      return this.contents.length > 0;
    }
  },
  methods: {
    handleChange(event) {
      const file = event[0];
      if (!file) {
        console.error("No file selected");
        return;
      }

      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.contents = reader.result;
      };
      reader.onerror = () => {
        console.log(reader.error);
      };
    },
    goToMapping() {
      let data = this.processData(this.contents);
      this.$router.push({
        name: 'ColumnMapping',
        params: { data: data }
      });
    },
    processData(allText) {
      if (!allText || (Array.isArray(allText) && allText.length === 0)) {
    return {
      headers: [],
      data: []
    };
  }

    const allTextLines = allText.split(/\r\n|\n/);
    const headers = allTextLines[0].split(',');
    let lines = [];

    for (let i = 1; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(',');
      if (data.length == headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    }

    const result = {
      headers: headers,
      data: lines
    };
    return result;
  }
  },
}
</script>
<style>
h2, h5{
  text-align: left;
}
h2{
  font-size: 24px !important;
  font-weight: 700;
  color: #18181A;
}
h5{
  font-size: 14px !important;
  color: #0C111D;
  font-weight: 500;
}
button {
  font-size: 14px;
}
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 438px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
.file-input{
  border:1px solid #D0D5DD;
  border-style:dashed;
}
</style>
